import { Injectable } from '@angular/core';
import { DocumentData, QueryDocumentSnapshot, doc, getDoc, setDoc } from '@angular/fire/firestore';
import {
  DgnbCriteriaGroup,
  DgnbIndicator,
  DgnbIndicatorCatalogue,
  DgnbSystem,
  PreCheckIndicator,
  PreCheckScenario,
} from '@eeule/eeule-shared';
import { FirebaseError } from 'firebase/app';
import { DocumentSnapshot, collection, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Observable, from, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DgnbSubjectEnum } from '../../enums/DgnbSubject.enum';
import { FirebaseService } from './firebase.service';
import { ProjectService } from './project.service';

export interface CreateScenarioOptions {
  name?: string;
  description?: string;
}
export interface EnrichedCriteriaGroupsWithSubject {
  groupsSnaps: QueryDocumentSnapshot<DocumentData, DocumentData>[];
  subject: DgnbSubjectEnum;
}

export interface EnrichedIndicatorCataloguesWithCriteriaGroupsAndSubject {
  cataloguesSnaps: QueryDocumentSnapshot<DocumentData, DocumentData>[];
  destinationGroupId: string;
  sourceGroupId: string;
  subject: DgnbSubjectEnum;
}
export interface EnrichedSingleIndicatorCatalogueWithCriteriaGroupAndSubject extends DgnbIndicatorCatalogue {
  destinationGroupId?: string;
  sourceGroupId?: string;
  subject?: DgnbSubjectEnum;
}

export interface EnrichedIndicatorsWithCatalogueAndCriteriaGroupAndSubject {
  indicatorSnaps?: QueryDocumentSnapshot<DocumentData, DocumentData>[];
  destinationCatalogueId?: string;
  sourceCatalogueId: string;
  destinationGroupId?: string;
  sourceGroupId: string;
  subject: DgnbSubjectEnum;
}
@Injectable({
  providedIn: 'root',
})
export class IndicatorService {
  constructor(private _firebaseService: FirebaseService, private _projectService: ProjectService) {}

  /** --- COMPANIES --- */
  public async getIndicatorCatalogue(id: string) {
    const docRef = doc(
      this._firebaseService.firestore,
      `environment/${environment.stage}/companies/${environment.companyId}/projects/b10uETGrVAM8hlupwyG6/indicatorCatalogue`,
      id
    );
    return getDoc(docRef);
  }

  public getIndicator(id: string): Promise<DocumentSnapshot<DocumentData, DocumentData>> {
    const docRef = doc(
      this._firebaseService.firestore,
      `environment/${environment.stage}/companies/${environment.companyId}/projects/b10uETGrVAM8hlupwyG6/indicators`,
      id
    );
    return getDoc(docRef);
  }
  /** ------ */

  /** --- DGNB SYSTEMS --- */
  public setDgnbSystem(dgnbSystem: DgnbSystem) {
    const docRef = doc(this._firebaseService.firestore, `dgnbSystems`, dgnbSystem.id);
    return from(setDoc(docRef, dgnbSystem));
  }

  public getDgnbSystem(id: string): Observable<DgnbSystem> {
    const docRef = doc(this._firebaseService.firestore, `dgnbSystems`, id);
    return from(getDoc(docRef)).pipe(
      map(systemSnap => {
        return { ...systemSnap.data(), id: systemSnap.id } as DgnbSystem;
      })
    );
  }

  public getPreCheckSystem(projectId: string, preCheckSystemId: string) {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/preCheckScenarios/${preCheckSystemId}`);
    return from(getDoc(docRef));
  }

  public getAllDgnbSystems(): Observable<QueryDocumentSnapshot<DocumentData, DocumentData>[]> {
    const colRef = collection(this._firebaseService.firestore, `dgnbSystems`); // FIXME: SHOULD be Name `dgnbSystems`but is already wrong collection Name in Database
    return from(getDocs(colRef)).pipe(map(system => system.docs));
  }

  // public getDgnbSystemSubjects(dgnbSystemId: string) {
  //   const docRef = doc(this._firebaseService.firestore, `dgnbSystems/${dgnbSystemId}`);
  //   return from(getDoc(docRef)).pipe(map(system => system.ref));
  // }

  public getAllCriteriaGroupsFromDgnbSubject(
    dgnbSystemId: string,
    subject: DgnbSubjectEnum
  ): Observable<QueryDocumentSnapshot<DocumentData, DocumentData>[]> {
    const docRef = collection(this._firebaseService.firestore, `dgnbSystems/${dgnbSystemId}/${subject}`);
    return from(getDocs(docRef)).pipe(map(criteriaGroups => criteriaGroups.docs));
  }

  public getAllCriteriaGroupsFromProjectSubject(
    projectId: string,
    scenarioType: string,
    systemId: string,
    subject: DgnbSubjectEnum
  ): Observable<QueryDocumentSnapshot<DocumentData, DocumentData>[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/${scenarioType}/${systemId}/${subject}`);
    return from(getDocs(colRef)).pipe(map(criteriaGroups => criteriaGroups.docs));
  }

  /**
   * Creates a DgnbCriteriaGroup at `dgnbSystems/dgnbSystemId/dgnbSubject/dgnbCriteriaGroup.id`.
   * For example: `dgnbSystems/abf7-0gd4/env/ad77-9bbf`.
   * Throws Error if DgnbCriteriaGroup.name does not fit dgnbSubject (E.g. If DgnbCriteriaGroup.name is 'PRO 1' but dgnbSubject is 'ENV')
   *
   * @author Severin Klug
   * @param {string} dgnbSystemId
   * @param {DgnbSubjectEnum} dgnbSubject
   * @param {DgnbCriteriaGroup} dgnbCriteriaGroup
   * @return {*}  {Observable<void>}
   * @memberof IndicatorService
   */
  public setDgnbCriteriaGroup(dgnbSystemId: string, dgnbSubject: DgnbSubjectEnum, dgnbCriteriaGroup: DgnbCriteriaGroup): Observable<void> {
    if (!dgnbCriteriaGroup.name.toLowerCase().includes(dgnbSubject.toLowerCase())) {
      const error: Error = new Error(
        `'Kriteriengruppe muss zu Thema passen! Thema: ${dgnbSubject},  Kriteriengruppe: ${dgnbCriteriaGroup.name}`
      );
      return throwError(() => error);
    }
    const docRef = doc(this._firebaseService.firestore, `dgnbSystems/${dgnbSystemId}/${dgnbSubject}`, dgnbCriteriaGroup.id);
    return from(setDoc(docRef, dgnbCriteriaGroup));
  }

  public setPreCheckCriteriaGroup(
    projectId: string,
    preCheckScenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    preCheckCriteriaGroup: DgnbCriteriaGroup
  ): Observable<void> {
    if (!projectId || !preCheckScenarioId || !dgnbSubject || !preCheckCriteriaGroup) {
      throw new Error(`Some parameters are not provided ${projectId ? '' : 'projectId'}
       ${preCheckScenarioId ? '' : 'preCheckScenarioId'}
       ${dgnbSubject ? '' : 'dgnbSubject'} ${preCheckCriteriaGroup ? '' : 'preCheckCriteriaGroup'}`);
    }

    const docRef = doc(
      this._firebaseService.firestore,
      `projects/${projectId}/preCheckScenarios/${preCheckScenarioId}/${dgnbSubject}/${preCheckCriteriaGroup.id}`
    );

    return from(setDoc(docRef, preCheckCriteriaGroup));
  }

  public getDgnbIndicatorCatalogue(
    dgnbSystemId: string,
    dgnbSubject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogueId: string
  ) {
    const docRef = doc(
      this._firebaseService.firestore,
      `dgnbSystems/${dgnbSystemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues/${indicatorCatalogueId}`
    );
    return from(getDoc(docRef));
  }

  public getAllDgnbIndicatorCataloguesFromCriteriaGroup(dgnbSystemId: string, dgnbSubject: DgnbSubjectEnum, dgnbCriteriaGroupId: string) {
    const docRef = collection(
      this._firebaseService.firestore,
      `dgnbSystems/${dgnbSystemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues`
    );
    return from(getDocs(docRef)).pipe(map(criteriaGroups => criteriaGroups.docs));
  }

  /**
   * Creates an IndicatorCatalogue at `dgnbSystems/dgnbSystemId/dgnbSubject/dgnbCriteriaGroupId/catalogues/indicatorCatalogue.id`.
   * For example: `dgnbSystems/abf7-0gd4/env/ad77-9bbf/catalogues/0316-4d4d`.
   * Throws Error if indicatorCatalogue.name does not fit dgnbSubject (E.g. If indicatorCatalogue.name is 'PRO 1.1' but dgnbSubject is 'ENV')
   *
   * @author Severin Klug
   * @param {string} dgnbSystemId
   * @param {DgnbSubjectEnum} dgnbSubject
   * @param {string} dgnbCriteriaGroupId
   * @param {DgnbIndicatorCatalogue} indicatorCatalogue
   * @return {*}  {Observable<void>}
   * @memberof IndicatorService
   */
  public setDgnbIndicatorCatalogue(
    dgnbSystemId: string,
    dgnbSubject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogue: DgnbIndicatorCatalogue
  ): Observable<void> {
    if (!indicatorCatalogue.name.toLowerCase().includes(dgnbSubject.toLowerCase())) {
      const error: Error = new Error(
        `'Indikatorkatalogname muss zu Thema passen! Thema: ${dgnbSubject},  Kriteriengruppe: ${indicatorCatalogue.name}`
      );
      return throwError(() => error);
    }
    const docRef = doc(
      this._firebaseService.firestore,
      `dgnbSystems/${dgnbSystemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues`,
      indicatorCatalogue.id
    );
    return from(setDoc(docRef, indicatorCatalogue));
  }

  public setProjectIndicatorCatalogue(
    projectId: string,
    scenarioType: string,
    systemId: string,
    dgnbSubject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogue: DgnbIndicatorCatalogue
  ): Observable<void> {
    if (!projectId || !scenarioType || !systemId || !dgnbSubject || !dgnbCriteriaGroupId || !indicatorCatalogue) {
      throw new Error(
        `Some parameters are not provided
        ${projectId ? '' : 'projectId'} ${scenarioType ? '' : 'scenarioType'}
        ${systemId ? '' : 'systemId'} ${dgnbSubject ? '' : dgnbSubject}
        ${dgnbCriteriaGroupId ? '' : 'dgnbCriteriaGroupId'} ${indicatorCatalogue ? '' : 'indicatorCatalogue'}`
      );
    }

    const docRef = doc(
      this._firebaseService.firestore,
      `projects/${projectId}/${scenarioType}/${systemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues`,
      indicatorCatalogue.id
    );
    return from(setDoc(docRef, indicatorCatalogue));
  }

  public getDgnbIndicator(
    dgnbSystemId: string,
    dgnbSubject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogueId: string,
    indicatorId: string
  ): Observable<DocumentSnapshot<DocumentData, DocumentData>> {
    const docRef = doc(
      this._firebaseService.firestore,
      `dgnbSystems/${dgnbSystemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues/${indicatorCatalogueId}/indicators`,
      indicatorId
    );
    return from(getDoc(docRef));
  }

  public getAllDgnbIndicatorsFromDgnbIndicatorCatalogue(
    dgnbSystemId: string,
    dgnbSubject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogueId: string
  ) {
    const docRef = collection(
      this._firebaseService.firestore,
      `dgnbSystems/${dgnbSystemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues/${indicatorCatalogueId}/indicators`
    );
    return from(getDocs(docRef)).pipe(map(indicators => indicators.docs));
  }

  public setDgnbIndicator(
    dgnbSystemId: string,
    dgnbSubject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogueId: string,
    indicator: DgnbIndicator
  ): Observable<void> {
    const docRef = doc(
      this._firebaseService.firestore,
      `dgnbSystems/${dgnbSystemId}/${dgnbSubject}/${dgnbCriteriaGroupId}/catalogues/${indicatorCatalogueId}/indicators`,
      indicator.id
    );
    return from(setDoc(docRef, indicator));
  }
  /** ------ */

  /** --- PRE CHECK SCENARIOS --- */
  public setPreCheckIndicatorCatalogue(
    projectId: string,
    preCheckScenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    preCheckCriteriaGroupId: string,
    indicatorCatalogue: DgnbIndicatorCatalogue
  ): Observable<void> {
    const docRef = doc(
      this._firebaseService.firestore,
      `projects/${projectId}/preCheckScenarios/${preCheckScenarioId}/${dgnbSubject}/${preCheckCriteriaGroupId}/catalogues/${indicatorCatalogue.id}`
    );
    return from(setDoc(docRef, indicatorCatalogue));
  }

  /* FIXME: This should be a cloud function (?) */
  public setPreCheckScenario(projectId: string, preCheckScenario: PreCheckScenario): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projcets/${projectId}/preCheckScenarios/${preCheckScenario.id}`);
    // TODO: Copy Cataglogues with fitting usageProfile
    return from(setDoc(docRef, preCheckScenario));
  }

  public getPreCheckIndicator(
    projectId: string,
    preCheckScenarioId: string,
    dgnbSuject: DgnbSubjectEnum,
    dgnbCriteriaGroupId: string,
    indicatorCatalogueId: string,
    indicatorId: string
  ): Observable<DocumentSnapshot<DocumentData, DocumentData>> {
    const docRef = doc(
      this._firebaseService.firestore,
      `projects/${projectId}/preCheckScenarios/${preCheckScenarioId}/${dgnbSuject}/${dgnbCriteriaGroupId}/catalogues/${indicatorCatalogueId}/indicators/${indicatorId}`
    );
    return from(getDoc(docRef));
  }

  public setPreCheckIndicator(
    projectId: string,
    scenarioType: string,
    systemId: string,
    dgnbSubject: DgnbSubjectEnum,
    preCheckCriteriaGroupId: string,
    indicatorCatalogueId: string,
    indicator: PreCheckIndicator
  ): Observable<void> {
    const docRef = doc(
      this._firebaseService.firestore,
      `projects/${projectId}/${scenarioType}/${systemId}/${dgnbSubject}/${preCheckCriteriaGroupId}/catalogues/${indicatorCatalogueId}/indicators/${indicator.id}`
    );
    return from(setDoc(docRef, indicator));
  }
  /** ------ */

  /** ---CLOUD FUNCTION CALLER--- */

  /**
   * calls copy process executed by cloud function
   *
   * @param {string} sourceSystemDocPath
   * @param {string} targetSystemDocPath
   * @returns
   *
   * @memberOf IndicatorService
   */
  public async cloud_copyDgnbSystemToProjectPreCheck(sourceSystemDocPath: string, targetSystemDocPath: string): Promise<unknown> {
    const functionName: string = 'callCopyDgnbSystem';
    const callCopyDgnbSystem = httpsCallable(this._firebaseService.functions, functionName);
    const _callCopyDgnbSystem = await callCopyDgnbSystem({
      sourceSystemDocPath: sourceSystemDocPath,
      targetSystemDocPath: targetSystemDocPath,
    })
      .then(result => {
        return result.data;
      })
      .catch(error => {
        if (error instanceof FirebaseError && error.code === 'functions/deadline-exceeded') {
          // Spezifischen Fehler ignorieren oder benutzerdefinierten Umgang damit implementieren
          console.warn('Deadline exceeded error occurred, ignoring:', error);
          // Optional: Leere Antwort oder spezielle Behandlung zurückgeben
          return { data: null };
        } else {
          // Andere Fehler behandeln
          console.error('Error calling function:', error);
          throw error;
        }
      });
    return _callCopyDgnbSystem;
  }
  /**
   * if `targetSystemColPath` is not provided, the target collection is automatically set to sourceCollection
   * and preCheckScenario is copied to preCheckScenarios, auditScenario to auditScenarios
   *
   * @param {string} userAuthUid the Users AuthId (from Firebase) needs to be send in order to create an customToken and later idToken for cloud function calls
   * @param {string} options provided Options for the creation request (name / description)
   * @param {string} sourceSystemCollectionPath projects/<projectID>/preCheckScenarios or projects/<projectID>/auditScenarios
   * @param {string} sourceScenarioId
   * @param {string} [targetSystemCollectionPath] projects/<projectID>/preCheckScenarios or projects/<projectID>/auditScenarios
   * @returns
   *
   * @memberOf IndicatorService
   */
  public cloud_createScenarioBasedOnExisting(
    userAuthUid: string,
    projectId: string,
    options: CreateScenarioOptions | null,
    sourceSystemCollectionPath: string,
    sourceScenarioId: string,
    targetSystemCollectionPath?: string
  ) {
    const functionName: string = 'callCreateScenario';
    const callCopyDgnbSystem = httpsCallable(this._firebaseService.functions, functionName);
    const _callCopyDgnbSystem = callCopyDgnbSystem({
      userAuthUid: userAuthUid,
      projectId: projectId,
      options: options,
      sourceSystemCollectionPath: sourceSystemCollectionPath,
      sourceScenarioId: sourceScenarioId,
      targetSystemCollectionPath: targetSystemCollectionPath,
    })
      .then(result => {
        return result.data;
      })
      .catch(error => {
        if (error instanceof FirebaseError && error.code === 'functions/deadline-exceeded') {
          // Spezifischen Fehler ignorieren oder benutzerdefinierten Umgang damit implementieren
          console.warn('Deadline exceeded error occurred, ignoring:', error);
          // Optional: Leere Antwort oder spezielle Behandlung zurückgeben
          return { data: null };
        } else {
          // Andere Fehler behandeln
          console.error('Error calling function:', error);
          throw error;
        }
      });
    return _callCopyDgnbSystem;
  }

  public cloud_createEmptyScenario(projectId: string, options: CreateScenarioOptions | null) {
    const functionName: string = 'callCreateScenario';
    const _dgnbSystem = this._projectService.project$.value?.dgnbSystem;
    const _projectId = this._projectService.project$.value?.id;
    if (!_dgnbSystem) {
      throw new Error('No DgnbSystem found in Project');
    }
    const callCopyDgnbSystem = httpsCallable(this._firebaseService.functions, functionName);
    const _callCopyDgnbSystem = callCopyDgnbSystem({
      userAuthUid: null,
      projectId: projectId,
      options: options,
      sourceSystemCollectionPath: `dgnbSystems`,
      sourceScenarioId: _dgnbSystem,
      targetSystemCollectionPath: `projects/${_projectId}/preCheckScenarios`,
    })
      .then(result => {
        return result.data;
      })
      .catch(error => {
        if (error instanceof FirebaseError && error.code === 'functions/deadline-exceeded') {
          // Spezifischen Fehler ignorieren oder benutzerdefinierten Umgang damit implementieren
          console.warn('Deadline exceeded error occurred, ignoring:', error);
          // Optional: Leere Antwort oder spezielle Behandlung zurückgeben
          return { data: null };
        } else {
          // Andere Fehler behandeln
          console.error('Error calling function:', error);
          throw error;
        }
      });
    return _callCopyDgnbSystem;
  }
}
